var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import classNames from 'classnames';
import RcInput from 'rc-input';
import { composeRef } from 'rc-util/lib/ref';
import React, { forwardRef, useContext, useEffect, useRef } from 'react';
import { ConfigContext } from '../config-provider';
import DisabledContext from '../config-provider/DisabledContext';
import SizeContext from '../config-provider/SizeContext';
import { FormItemInputContext, NoFormStyle } from '../form/context';
import { NoCompactStyle, useCompactItemContext } from '../space/Compact';
import { getMergedStatus, getStatusClassNames } from '../_util/statusUtils';
import warning from '../_util/warning';
import useRemovePasswordTimeout from './hooks/useRemovePasswordTimeout';
import { hasPrefixSuffix } from './utils';
// CSSINJS
import useStyle from './style';
export function triggerFocus(element, option) {
    if (!element) {
        return;
    }
    element.focus(option);
    // Selection content
    const { cursor } = option || {};
    if (cursor) {
        const len = element.value.length;
        switch (cursor) {
            case 'start':
                element.setSelectionRange(0, 0);
                break;
            case 'end':
                element.setSelectionRange(len, len);
                break;
            default:
                element.setSelectionRange(0, len);
                break;
        }
    }
}
const Input = forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, bordered = true, status: customStatus, size: customSize, disabled: customDisabled, onBlur, onFocus, suffix, allowClear, addonAfter, addonBefore, className, rootClassName, onChange, classNames: classes } = props, rest = __rest(props, ["prefixCls", "bordered", "status", "size", "disabled", "onBlur", "onFocus", "suffix", "allowClear", "addonAfter", "addonBefore", "className", "rootClassName", "onChange", "classNames"]);
    const { getPrefixCls, direction, input } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('input', customizePrefixCls);
    const inputRef = useRef(null);
    // Style
    const [wrapSSR, hashId] = useStyle(prefixCls);
    // ===================== Compact Item =====================
    const { compactSize, compactItemClassnames } = useCompactItemContext(prefixCls, direction);
    // ===================== Size =====================
    const size = React.useContext(SizeContext);
    const mergedSize = compactSize || customSize || size;
    // ===================== Disabled =====================
    const disabled = React.useContext(DisabledContext);
    const mergedDisabled = customDisabled !== null && customDisabled !== void 0 ? customDisabled : disabled;
    // ===================== Status =====================
    const { status: contextStatus, hasFeedback, feedbackIcon } = useContext(FormItemInputContext);
    const mergedStatus = getMergedStatus(contextStatus, customStatus);
    // ===================== Focus warning =====================
    const inputHasPrefixSuffix = hasPrefixSuffix(props) || !!hasFeedback;
    const prevHasPrefixSuffix = useRef(inputHasPrefixSuffix);
    useEffect(() => {
        var _a;
        if (inputHasPrefixSuffix && !prevHasPrefixSuffix.current) {
            warning(document.activeElement === ((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.input), 'Input', `When Input is focused, dynamic add or remove prefix / suffix will make it lose focus caused by dom structure change. Read more: https://ant.design/components/input/#FAQ`);
        }
        prevHasPrefixSuffix.current = inputHasPrefixSuffix;
    }, [inputHasPrefixSuffix]);
    // ===================== Remove Password value =====================
    const removePasswordTimeout = useRemovePasswordTimeout(inputRef, true);
    const handleBlur = (e) => {
        removePasswordTimeout();
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    };
    const handleFocus = (e) => {
        removePasswordTimeout();
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
    };
    const handleChange = (e) => {
        removePasswordTimeout();
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
    };
    const suffixNode = (hasFeedback || suffix) && (React.createElement(React.Fragment, null,
        suffix,
        hasFeedback && feedbackIcon));
    // Allow clear
    let mergedAllowClear;
    if (typeof allowClear === 'object' && (allowClear === null || allowClear === void 0 ? void 0 : allowClear.clearIcon)) {
        mergedAllowClear = allowClear;
    }
    else if (allowClear) {
        mergedAllowClear = { clearIcon: React.createElement(CloseCircleFilled, null) };
    }
    return wrapSSR(React.createElement(RcInput, Object.assign({ ref: composeRef(ref, inputRef), prefixCls: prefixCls, autoComplete: input === null || input === void 0 ? void 0 : input.autoComplete }, rest, { disabled: mergedDisabled, onBlur: handleBlur, onFocus: handleFocus, suffix: suffixNode, allowClear: mergedAllowClear, className: classNames(className, rootClassName, compactItemClassnames), onChange: handleChange, addonAfter: addonAfter && (React.createElement(NoCompactStyle, null,
            React.createElement(NoFormStyle, { override: true, status: true }, addonAfter))), addonBefore: addonBefore && (React.createElement(NoCompactStyle, null,
            React.createElement(NoFormStyle, { override: true, status: true }, addonBefore))), classNames: Object.assign(Object.assign({}, classes), { input: classNames({
                [`${prefixCls}-sm`]: mergedSize === 'small',
                [`${prefixCls}-lg`]: mergedSize === 'large',
                [`${prefixCls}-rtl`]: direction === 'rtl',
                [`${prefixCls}-borderless`]: !bordered,
            }, !inputHasPrefixSuffix && getStatusClassNames(prefixCls, mergedStatus), classes === null || classes === void 0 ? void 0 : classes.input, hashId) }), classes: {
            affixWrapper: classNames({
                [`${prefixCls}-affix-wrapper-sm`]: mergedSize === 'small',
                [`${prefixCls}-affix-wrapper-lg`]: mergedSize === 'large',
                [`${prefixCls}-affix-wrapper-rtl`]: direction === 'rtl',
                [`${prefixCls}-affix-wrapper-borderless`]: !bordered,
            }, getStatusClassNames(`${prefixCls}-affix-wrapper`, mergedStatus, hasFeedback), hashId),
            wrapper: classNames({
                [`${prefixCls}-group-rtl`]: direction === 'rtl',
            }, hashId),
            group: classNames({
                [`${prefixCls}-group-wrapper-sm`]: mergedSize === 'small',
                [`${prefixCls}-group-wrapper-lg`]: mergedSize === 'large',
                [`${prefixCls}-group-wrapper-rtl`]: direction === 'rtl',
                [`${prefixCls}-group-wrapper-disabled`]: mergedDisabled,
            }, getStatusClassNames(`${prefixCls}-group-wrapper`, mergedStatus, hasFeedback), hashId),
        } })));
});
export default Input;
